<!--
 * @Author: qy
 * @LastEditors: qy
 * @description: page description
 * @Date: 2020-07-31 11:23:58
 * @LastEditTime: 2020-07-31 11:33:56
--> 
<template>
  <el-dialog :visible.sync="dialog" append-to-body :title="titleNames[type]" width="1200px">
    <el-table v-loading="loading" row-key="id" :data="data" border>
      <el-table-column prop="applyTypeName" label="返利申请类型" width="100" />
      <el-table-column prop="applyFormCode" label="返利申请单号" width="150" />
      <el-table-column prop="_payMoney" :label="moneyNames[type]" width="120" align="right" :formatter="$price" />
      <el-table-column prop="recordTime" label="记录时间" width="150" :formatter="r => { return r.recordTime?new Date(r.recordTime).format():'' }" />
      <template v-if="type === 1">
        <el-table-column key="relationFormType" prop="relationFormType" label="单据类型" width="100">
          <template slot-scope="scope">{{relationFormType[scope.row.relationFormType]}}</template>
        </el-table-column>
        <el-table-column key="relationFormCode" prop="relationFormCode" label="单据编号" width="120" />
      </template>
      <el-table-column prop="recordType" label="操作" min-width="100">
        <template slot-scope="scope">{{recordTypes[scope.row.recordType || 0]}}</template>
      </el-table-column>
      <el-table-column key="info" prop="info" label="备注" min-width="200" show-overflow-tooltip />
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
  </el-dialog>
</template>

<script>
import initData from "@/mixins/initData";

export default {
  mixins: [initData],
  data() {
    return {
      dialog: false,
      type: 0,
      titleNames: ["获得返利明细", "使用返利明细"],
      moneyNames: ["获得返利金额", "使用返利金额"],
      recordTypes: [
        "采购下单",
        "采购付款",
        "退货退款",
        "充值",
        "提现",
        "返利入账",
        "返利出账",
        "费用单下单",
        "费用单付款",
        "其它",
      ],
      relationFormType: {
        order: "销售单",
        purchase: "采购单",
        orderBack: "退单",
        payForm: "收退款单",
        rebateApply: "返利申请单",
        bill: "费用单",
      }
    };
  },
  methods: {
    beforeInit() {
      this.url = "api/capitalPool/rebateReport/info";
      return true;
    },
    afterLoad(res) {
      res.forEach((o) => {
        o._payMoney = Math.abs(o.payMoney);
      });
    },
    research(recordId, type) {
      this.dialog = true;
      this.type = type;
      this.params = {
        recId: recordId,
        sort: "recordTime,desc",
      };
      this.toQuery();
    },
  },
};
</script>